function addNewInputToForm(form, fileElement) {
  const fileData = JSON.parse(fileElement.getAttribute('data-file'))

  const inputElement = document.createElement('input')
  inputElement.type = 'hidden'
  inputElement.name = fileElement.name + '_key'
  inputElement.value = fileData.key

  form.appendChild(inputElement)

  // reset name of original file element
  // to not go to server
  fileElement.name = ''
}

export function areAllFilesUploaded(formElement) {
  const fileElements = formElement.querySelectorAll('.s3-upload-item')

  let allUploaded = true

  fileElements.forEach((fileElement) => {
    if (fileElement.files.length == 0) return

    const data = JSON.parse(fileElement.getAttribute('data-file'))
    if (data == undefined || data.is_uploaded != true) {
      allUploaded = false
    }
  })

  return allUploaded
}

export function isFileUploaded(fileElement) {
  if (fileElement.files.length == 0) {
    return true
  }

  const data = JSON.parse(fileElement.getAttribute('data-file'))
  return data != undefined && data.is_uploaded == true
}

export function generatePresignedUrl(prefix, fileElement, callback) {
  const file = fileElement.files[0]

  const file_name = encodeURIComponent(file.name)
  const file_type = encodeURIComponent(file.type)

  fetch(
    `/uploader/presign_s3/?file_name=${file_name}&file_type=${file_type}&prefix=${prefix}`,
  )
    .then((response) => response.json())
    .then((data) => {
      fileElement.setAttribute('data-file', JSON.stringify(data))

      if (callback) {
        callback(data)
      }
    })
}

export function uploadFileToS3(fileElement, successCallback, errorCallback) {
  const file = fileElement.files[0]
  const data = JSON.parse(fileElement.getAttribute('data-file'))
  const presignedUrl = data.presigned_url

  fetch(presignedUrl, {
    method: 'PUT',
    body: file,
  })
    .then((response) => response.text())
    .then((result) => {
      data.is_uploaded = true
      fileElement.setAttribute('data-file', JSON.stringify(data))

      successCallback(result)
    })
    .catch((error) => {
      errorCallback(error)
    })
}

export function uploadFileInForm(formElement, completeCallback) {
  const fileElements = formElement.querySelectorAll('.s3-upload-item')
  if (fileElements.length == 0) {
    completeCallback()
    return
  }

  fileElements.forEach((fileElement) => {
    const data = JSON.parse(fileElement.getAttribute('data-file'))
    if (data == undefined || data.is_uploaded) return

    uploadFileToS3(
      fileElement,
      (result) => {
        addNewInputToForm(formElement, fileElement)
        if (!areAllFilesUploaded(formElement)) return

        completeCallback(result)
      },
      (error) => {
        console.log(error)
      },
    )
  })
}

export function generatePresignedUrls(prefix, fileElement, callback) {
  const files = fileElement.files
  const promises = []

  // Iterate over each file and create a fetch promise
  for (let i = 0; i < files.length; i++) {
    const file = files[i]
    const file_name = encodeURIComponent(file.name)
    const file_type = encodeURIComponent(file.type)

    const promise = fetch(
      `/uploader/presign_s3/?file_name=${file_name}&file_type=${file_type}&prefix=${prefix}`,
    )
      .then((response) => response.json())
      .then((data) => {
        return { file, presignedUrlData: data } // Return both file and presigned URL data
      })

    promises.push(promise)
  }

  // Process all the fetch promises
  Promise.all(promises)
    .then((results) => {
      const presignedUrls = results.map((result) => result.presignedUrlData)

      // Optionally, store the presigned URLs in a data attribute
      fileElement.setAttribute('data-file', JSON.stringify(presignedUrls))

      if (callback) {
        callback(presignedUrls) // Call the callback with the array of presigned URL data
      }
    })
    .catch((error) => {
      console.error('Error generating presigned URLs:', error)
    })
}

export function uploadFilesToS3(
  prefix,
  fileElement,
  successCallback,
  errorCallback,
) {
  if (isFileUploaded(fileElement)) {
    successCallback([])
  }

  if (fileElement.getAttribute('data-file')) {
    _uploadFilesToS3(fileElement, successCallback, errorCallback)
  }

  generatePresignedUrls(prefix, fileElement, () => {
    _uploadFilesToS3(fileElement, successCallback, errorCallback)
  })
}

function _uploadFilesToS3(fileElement, successCallback, errorCallback) {
  const files = fileElement.files
  const dataFiles = JSON.parse(fileElement.getAttribute('data-file'))

  if (files.length !== dataFiles.length) {
    throw new Error(
      `Number of files (${files.length}) and data-file (${dataFiles.length}) entries must match.`,
    )
  }

  const uploadPromises = []

  for (let i = 0; i < files.length; i++) {
    const file = files[i]
    const data = dataFiles[i]
    const presignedUrl = data.presigned_url

    const uploadPromise = fetch(presignedUrl, {
      method: 'PUT',
      body: file,
    })
      .then((response) => response.text())
      .then((result) => {
        data.is_uploaded = true
        return result
      })
      .catch((error) => {
        errorCallback(error)
        throw error // Re-throw the error so it can be caught by Promise.all
      })

    uploadPromises.push(uploadPromise)
  }

  Promise.all(uploadPromises)
    .then((results) => {
      // Update the data-file attribute after all files are uploaded
      fileElement.setAttribute('data-file', JSON.stringify(dataFiles))
      successCallback(dataFiles)
    })
    .catch((error) => {
      console.error('Error uploading files:', error)
    })
}

export function addHiddenInput(formElement, name, value) {
  const hiddenInput = document.createElement('input')
  hiddenInput.type = 'hidden'
  hiddenInput.name = name
  hiddenInput.value = value
  formElement.appendChild(hiddenInput)
}
